import React, { useEffect, useRef, useState } from 'react';
import './style.css'; // Custom CSS file for styling
import { Rating } from 'react-simple-star-rating'
import axios from 'axios';
import base_url from '../../../base_url';
import { Spinner } from 'react-activity';

const _orderId =  window.location.pathname.split('/')[2]

const CompleteOrder= ({user,socket,detectPageChange}) => {
  const [comment, setComment] = useState('');
  const [rating,setRating] = useState("")
  const [isLoading,setisLoading] = useState(false)
  const [error,setError ] = useState("")
  const [completingOrderLoading,setCompletingOrderLoading] = useState(true)
  const shouldLog  = useRef(true)

  

  const handleSubmitReview = async() => {
   const orderId = await window.location.pathname.split('/')[2]
   const user =await localStorage.getItem('handyman_ioiasod8921A_user')
   if(rating != ""){
    setisLoading(true)
    let formData = new FormData()
    formData.append('order_id',orderId)
    formData.append('rating',rating)

    formData.append('comment',comment)

    axios.post(`${base_url}/apis/order/complete_order_1`,formData,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json',
      },
    })
    .then(res=>{
      if(res.status == 201){
        alert("Rated the order successfully")
        window.location = `/single_order/${orderId}`
      }else{
        alert(res.data.message)
      }
      setisLoading(false)
    })
    .catch(err=>{
     if(err && err.response && err.response.data.message){
        alert(err.response.data.message)
       }else{
        alert("Required Operation Failed!")
      }
     
      setisLoading(false)

    })
   }else{
    alert("Rating and Comment is required")
   }
  };


  const MakeOrderAsCompleted = async()=>{
    const params = new URLSearchParams(window.location.search);

    const orderId = await window.location.pathname.split('/')[2]
    const user =await localStorage.getItem('handyman_ioiasod8921A_user')
   
    const paymentId = params.get('token')
    let formData = new FormData()
    formData.append('order_id',orderId)
    formData.append('rating',rating)
 
    formData.append('paymentId',paymentId)

    formData.append('comment',comment)

    axios.post(`${base_url}/apis/order/complete_order_0`,formData,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json',
      },
    })
    .then(res=>{
     
        setCompletingOrderLoading(false)

     
    })
    .catch(err=>{
    
      
      // setError("Required Operation Failed!")
      if(err && err.response && err.response.data.message){
        setError(err.response.data.message)
       }else{
        setError("Required Operation Failed!")
      }
      setCompletingOrderLoading(false)

    })

  }

  useEffect(()=>{
    if(shouldLog.current){
      shouldLog.current = false
      socket.disconnect()

      detectPageChange(window.location.pathname)

      MakeOrderAsCompleted()

    }
  },[])

  
if(completingOrderLoading == false){
  if(error == ""){

  return (
    <div className="complete_container">
      <h2>Order Completed</h2>
      <p>Thank you for completing the order!</p>
      <h3>Leave a Review</h3>
      <textarea
        className="form-control"
        placeholder="Write your review here..."
        value={comment}
        onChange={(val)=> setComment(val.target.value)}
      ></textarea>
       <Rating onClick={(val)=>{
        setRating(val)
       }} />
      <button className="btn-submit-review" onClick={handleSubmitReview}>
        {isLoading?<Spinner color='skyblue'/>:null} Submit Review
      </button>
    </div>
  );
}else{
 return <center className="container border p-4" style={{borderRadius:10}}>
  <div className="row">
      <div className="col-md-12">
      <i class="fa fa-globe fa-5x text-primary"></i>
          <h2 >{error}</h2>
          <br />
          <button className="btn btn-primary" onClick={()=>{
           window.location = `/single_order/${_orderId}`
          }}>Try Again</button>

      </div>
  </div>
</center>
}

}else{
  return <center><Spinner size={25} color='skyblue'/></center>
}


};

export default CompleteOrder;
